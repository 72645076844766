<template>
  <v-container grid-list-sm>
    <v-layout wrap justify-center>
      <v-flex xs6>
        <v-card v-if="!loginStatus" color="loadingPanelBackground">
          <v-card-text>
            <span class="loadingPanelColor--text">
              {{ $t("login.loadUser") }}
            </span>
            <v-progress-linear
              indeterminate
              color="loadingPanelColor"
              class
            ></v-progress-linear>
          </v-card-text>
        </v-card>
        <v-card v-else dark color="error">
          <v-card-text>
            <IconBox color="iconButton">fas fa-exclamation-circle</IconBox>
            {{ $t(`login.${loginStatus}`) }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="signOutOidc" dark text>{{
              $t("common.logout")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import LoginService from "@/services/LoginService";

export default {
  name: "OidcCallback",
  data() {
    return {
      loginStatus: "",
    };
  },
  computed: mapState("oidcStore", ["user"]),
  methods: {
    ...mapActions("oidcStore", ["oidcSignInCallback", "signOutOidc"]),
    ...mapActions("settings", ["fetchUserRestrictions"]),
    ...mapActions({
      loadComplaintStates: "loadComplaintStates",
    }),
  },
  mounted() {
    this.oidcSignInCallback()
      .then(async (redirectPath) => {
        LoginService.UpdateUserModifiTime(this.user.userId);

        var result = await this.fetchUserRestrictions({
          userId: this.user.userId,
        });

        if (result.isError) {
          this.loginStatus = result.loginStatus;
          return;
        }

        if (!result.isActive) {
          this.loginStatus = "login_error_inactiveuser";
          return;
        }

        const origin = redirectPath.slice(1);

        if (origin) {
          this.$router.push({ path: redirectPath });
        } else {
          this.$router.push({ name: "home" });
        }

        this.loadComplaintStates();
      })
      .catch(() => {
        this.loginStatus = "login_error";
      });
  },
};
</script>
